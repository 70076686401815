import React from 'react';
import {Helmet} from "react-helmet";

const IeltsPte = () => {
  return (
    <section className="bg-gray-100 py-16 px-8">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h5 className="text-4xl font-bold text-red-600">IELTS/PTE</h5>
      </div>

      {/* First Section: Image on Left, Content on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/2147844692.jpg?updatedAt=1726566862697"
            alt="Travel"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
        <div className="lg:w-1/2 lg:pl-8">
          <p className="text-lg text-gray-700 mb-6">
          Whether you are looking to take the IELTS for study, work, or migration, or you need guidance on the PTE exam, My Visa Point is here to help. With our expert team as your partners in success, you can be confident in your preparation.
          </p> 
           <p className="text-lg text-gray-700 mb-6">
           At My Visa Point, we understand the importance of language proficiency tests like the IELTS (International English Language Testing System) and PTE (Pearson Test of English). As a trusted name among best study visa consultants in Chandigarh, we provide comprehensive preparation services to help you achieve the scores you need for study or immigration purposes.
          </p>
          {/* <p className="text-lg text-gray-700 mb-6">
          PTE is an English language proficiency test designed to assess the ability to use English in academic and professional contexts.
          </p> */}
        
        </div>
      </div>

      {/* Second Section: Content on Left, Image on Right */}
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center mb-16">
        <div className="lg:w-1/2 lg:pr-8">
          <div className="bg-white p-8 rounded-lg shadow-lg mb-8 lg:mb-0">
            <h6 className="text-2xl font-semibold text-red-500 mb-4">Choosing Between IELTS and PTE:</h6>
            <p className="text-gray-700 mb-4">Expert Guidance: Our team consists of experienced instructors who specialize in preparing students for IELTS and PTE exams, offering tailored strategies to maximize your score.</p>
            
          
            <p className="text-gray-700 mb-4">Comprehensive Study Materials: We provide access to a wealth of resources, including practice tests, sample questions, and study guides designed to enhance your understanding of the exam format..</p>
            
          
            <p className="text-gray-700 mb-4">	Flexible Class Schedules: Our courses are designed to fit your busy schedule, with options for both weekday and weekend classes..</p>
            
           
            <p className="text-gray-700 mb-4">	Mock Tests: Regular mock tests simulate the actual exam environment, helping you build confidence andimprove your time management skills.</p>
           
            
          </div>
        </div>
        <div className="lg:w-1/2">
          <img
            src="https://ik.imagekit.io/6oa6qejxe/2148854879.jpg?updatedAt=1726567402863"
            alt="Adventure"
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500"
          />
        </div>
      </div>
      <Helmet>
                <meta charSet="utf-8" />
                <title>IELTS & PTE Coaching in Chandigarh | My Visa Point</title>
                <meta name="description" content=" IELTS and PTE exams with expert coaching at My Visa Point, Chandigarh. Join us for tailored classes and achieve your study abroad goals today!
" />
                <link rel="canonical" href="https://myvisapoint.com/IeltsPte" />
            </Helmet>
      
      
    </section>
  );
};

export default IeltsPte;
