import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const DiscountModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Automatically open the modal after 8 seconds
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 8000);

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const toggleModal = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 300); // Match the duration of your closing animation
    } else {
      setIsOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://api.myvisapoint.com/api/contact/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        setSuccess('Message sent successfully!');
        setForm({ name: '', email: '', message: '' });
        setError('');
      } else {
        throw new Error('Failed to send message');
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
      setSuccess('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* Message Icon */}
      <div
        className={`fixed right-4 cursor-pointer bg-orange-600 text-white p-5 rounded-full shadow-lg transform transition-all duration-300 ease-in-out ${isOpen ? 'bottom-96' : 'bottom-4'}`}  // Smooth transition for icon position
        onClick={toggleModal}
      >
        <i className="fa-solid fa-envelope fa-2xl"></i>
      </div>

      {/* Modal */}
      {isOpen && (
        <div className={`fixed bottom-0 left-0 right-0 flex items-center justify-center z-[9999] transition-transform duration-500 ease-in-out ${isClosing ? 'transform translate-y-full' : ''}`}>
          <div className="bg-gradient-to-r from-red-700 to-yellow-600 rounded-t-lg w-full h-auto shadow-lg p-4 sm:p-6 md:p-8" data-aos="slide-up" data-aos-duration="300">
            <div className="flex justify-between items-center pb-3">
              <button onClick={toggleModal} className="text-gray-500 hover:text-gray-800 text-3xl">
                &times;
              </button>
            </div>
            <div className="flex flex-col md:flex-row">
              {/* Logo Section */}
              <div className="w-full md:w-1/3 flex items-center justify-center">
                <img src="https://ik.imagekit.io/7uve7qsipm/myVisaPoint_logo.png?updatedAt=1725612878801" alt="Logo" className="w-24 h-24 sm:w-32 sm:h-32 md:w-48 md:h-48 object-contain" />
              </div>

              {/* Content Section */}
              <div className="w-full md:w-1/3 p-4 flex flex-col items-start">
                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold mb-2 text-center md:text-left">
                  DON'T LET YOUR WEBSITE HOLD YOU BACK!
                </h2>
                <p className="text-gray-700 text-sm sm:text-base md:text-xl text-center md:text-left">
                  Contact us for Visa & Immigration services!
                </p>
              </div>

              {/* Form Section */}
              <div className="w-full md:w-1/3 p-2 bg-gray-800 bg-opacity-20 rounded-lg shadow-lg">
                <form onSubmit={handleSubmit}>
                  <label className="block mb-3">
                    <input
                      type="text"
                      name="name"
                      value={form.name}
                      onChange={handleChange}
                      placeholder="Your Name"
                      className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                      required
                    />
                  </label>
                  <label className="block mb-3">
                    <input
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                      className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                      required
                    />
                  </label>
                  <label className="block mb-3">
                    <textarea
                      name="message"
                      value={form.message}
                      onChange={handleChange}
                      placeholder="Your Message"
                      className="mt-1 block w-full p-2 border border-gray-600 rounded-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
                      rows="3"
                      required
                    ></textarea>
                  </label>
                  {success && <p className="text-green-500 text-center">{success}</p>}
                  {error && <p className="text-red-500 text-center">{error}</p>}
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-black text-white py-2 rounded-full hover:bg-blue-700 transition duration-200"
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscountModal;
